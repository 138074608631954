import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Split Jerk 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Band Face Pulls 10-10-10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`15-12-9-6-3 reps each of:`}</p>
    <p>{`Push Jerk (135/95)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 lap run`}</p>
    <p>{`20 GHD Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free Crossfit Class at 9:00am and 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Register now at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      